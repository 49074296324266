<template>
<div id="indexlayout">
  <layout-index-left />
  <div id="indexlayout-right" :class="{'fiexd-header': siteFiexdHeader}">
    <layout-index-right-top />
    <div class="indexlayout-right-main">
      <div class="main-conent main-conent-minheight">
        <el-card shadow="never" class="border-none margin-t24">
          <el-form :model="form" label-width="120px"   :rules="rules"
          ref="form">
            <el-form-item label="用户名" prop="name">
              <el-input v-model="form.name"  style="width:400px" disabled></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" label-width="120px">
              <el-input v-model="form.password" type="password" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password" label-width="120px">
              <el-input v-model="form.new_password" type="password" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="再一次新密码" prop="new_password2" label-width="120px">
              <el-input v-model="form.new_password2"  type="password" style="width:400px"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" style="margin-left:120px;margin-top:10px;" @click="submitForm('form')">完成</el-button>
        </el-card>
      </div>
      <layout-index-right-footer />
    </div>
  </div>

</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import LayoutIndexLeft from '@/layout/Index/LayoutIndexLeft';
import LayoutIndexRightTop from '@/layout/Index/LayoutIndexRightTop';
import LayoutIndexRightFooter from '@/layout/Index/LayoutIndexRightFooter';
import request from '@/service/lib/request';
export default {
  components: {
    LayoutIndexLeft,
    LayoutIndexRightTop,
    LayoutIndexRightFooter
  },
  data() {
     
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      radio1: '全部',
      input1: '',
      createDialogFlag: false,
      form: {
        name: '',
        password: '',
        new_password:'',
        new_password2:''
      },

       rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        new_password2: [{ validator: validatePass2, trigger: "blur" }]
      }
    };
  },
  computed: {
    ...mapGetters([
      'siteFiexdHeader'
    ])
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.form.name=this.$store.getters.name;
    },
    handleClick(row) {
      console.log(row);
    },
    submitForm(form){
       this.$refs[form].validate((valid) => {
        if (valid) {
          this.setPassword();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setPassword() {
      request.put('/user/password', this.form)
        .then((res) => {
          console.log(res);
          if (res.code === "success") {
            this.$message({
              type: "success",
              message: "修改密码成功！请重新登录！"
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: auto;
  background-color: $mainBgColor;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      overflow: auto;
    }
  }

}
</style>
